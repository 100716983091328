import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import {
  CircularProgress,
  CircularProgressLabel,
  Box,
  Center,
} from "@chakra-ui/react";
import { OpaliteTextStandard } from "../shared/opalite_text";
import ProgressBar from "./progress_bar";

interface LoadingProps {}

const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <Center
      display="flex"
      justifyItems="center"
      alignItems="center"
      width="100%"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      height="100vh"
    >
      <Center flexDirection="column" justifyItems="center" alignItems="center">
        <ProgressBar
          progress={80}
          size={150}
          strokeWidth={15}
          circleOneStroke="#181818"
        />

        <OpaliteTextStandard>Loading your feed...</OpaliteTextStandard>
      </Center>
    </Center>
  );
};

export default Loading;
